import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'deunam-console-massivetxns-ng',
  template: ` <router-outlet> </router-outlet> `,
})
export class AppComponent implements OnInit {
  title = 'deunam-console-massivetxns-ng';
    constructor(private primengConfig: PrimeNGConfig) {}
    ngOnInit() {
        this.primengConfig.ripple = true;
    }
}
