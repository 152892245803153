import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { AuthorizationGuard } from '@deuna/bo-sh-security-ng';
import { backOfficePeopleRoles } from './core/constants/constants';

const routes: Routes = [
  {
    path: 'people/customer-management',
    loadChildren: () =>
      import('./modules/customer-management/customer-management.module').then(
        (m) => m.CostumerManagementModule
      ),
    canLoad: [ AuthorizationGuard],
    canActivateChild: [],
    canActivate: [AuthorizationGuard],
    data: {
      requiredRoles: [backOfficePeopleRoles.READER],
    },
  },
  {
    path: '**',
    component: EmptyRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
