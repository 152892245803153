// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../node_modules/primeng/resources/themes/saga-blue/theme.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../node_modules/primeng/resources/primeng.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
html {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-size: 14;
}

.text-style-back {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #4C1D80;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AACA,8EAAA;AAGA;EACI,gDAAA;EACA,kBAAA;EACA,aAAA;AAAJ;;AAKA;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,eAAA;AAFJ","sourcesContent":["\r\n/* You can add global styles to this file, and also import other style files */@import 'primeng/resources/themes/saga-blue/theme.css';\r\n@import 'primeng/resources/primeng.min.css';\r\n\r\nhtml{\r\n    font-family: \"Montserrat\", sans-serif !important;\r\n    font-style: normal;\r\n    font-size: 14;\r\n}\r\n\r\n\r\n\r\n.text-style-back{\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    text-decoration-line: underline;\r\n    color: #4C1D80;\r\n    cursor: pointer;\r\n}\r\n\r\n\r\n\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
